import { FC, useState } from 'react'

// assets
import State1Logo from './assets/logo-state1.png';

// style
import { SectionsBase } from '../style/Sections.style';
import { 
    Section,
    SectionTitle,
    SectionText,
    AccessButton
} from './style/VirtualRoom.style';


const VirtualRoom: FC<{flavour?: 'ellenikaBlue'}> = ({ flavour }) => {

    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

    const onButtonClick = () => {
        window.location.href = 'https://app.ellenikautility.com';
    };

    const onMouseOverHandler = () => setIsMouseOver(true);
    const onMouseLeaveHandler = () => setIsMouseOver(false);

    return (
        <SectionsBase flavour={flavour}>
            <Section>
                <SectionTitle>
                    Ellenika Zone - State1
                </SectionTitle>

                <SectionText>
                    Siamo lieti di informarvi che Ellenika Utility ha recentemente acquisito un'area in Virtual
                    Reality di 8.000.00 mq, grazie alla partnership con il metaverso di State1. Questo ci
                    permetterà di ampliare ulteriormente le nostre promozioni e offrire un servizio a 360
                    gradi ai nostri utenti. L'area in questione è stata denominata "Ellenika Zone". Grazie a
                    questa soluzione potrai sperimentare una nuova dimensione di utilità e servizi.
                </SectionText>

                <SectionText>
                    Con Ellenika Zone, potrai godere della stessa comodità che hai sempre avuto con le utility tradizionali, ma in
                    un mondo digitale che ti offre infinite opportunità di scoperta e fruizione. Non perdere l'occasione di
                    sperimentare il meglio della tecnologia digitale.
                    Grazie al potere della realtà virtuale e alle utility innovative di Ellenika, potrai vivere un'esperienza unica!
                    Resta aggiornato per scoprire tutte le offerte e come sfruttarle al meglio per arricchire la tua esperienza.
                </SectionText>

                {/* <iframe src='https://vr.state1.io/s1-lobby/' /> */}

                <img alt='State1 logo' src={State1Logo} />

                <AccessButton 
                    onClick={onButtonClick}
                    onMouseOver={onMouseOverHandler}
                    $isMouseOver={isMouseOver}
                    onMouseLeave={onMouseLeaveHandler}
                >
                    Accedi ad ellenika zone / state1
                </AccessButton>
            </Section>
        </SectionsBase>
    );
};

export default VirtualRoom;
