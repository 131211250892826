import { FC } from 'react';

//assets
import Desktop from './assets/desktop.png';
import DiscordIcon from './assets/discord-white-icon.jpg';

//style
import { SectionsBase } from '../style/Sections.style';
import {
    Section,
    Title,
    SectionBox,
    TextContainer,
    TextBox,
    TextTitle,
    SectionText,
    ImageBox,
    NftImage,
    JoinButton,
    ButtonText
} from './style/BenefitsAndAdvantage.style';


const BenefitsAndAdvantage: FC<{flavour?: 'ellenikaBlue'}> = ({ flavour }) => {

    const onClickHandler = () => window.location.href = 'https://discord.gg/Gn5qpDdqwF';

    return (
        <SectionsBase id='vantaggi' flavour={flavour}>
            <Section>
                <SectionBox>
                    <ImageBox>
                        <NftImage alt='Phone with book NFT' src={Desktop} />
                    </ImageBox>

                    <TextContainer>
                        <Title>
                            Benefici e vantaggi
                        </Title>

                        <TextBox>
                            <SectionText>
                                <strong>Ma c'è di più:</strong> Ellen Pass garantisce vantaggi a lungo termine. Mentre altre promozioni
                                e offerte potrebbero avere una scadenza, i vantaggi offerti da Ellenika Utility saranno
                                lifetime. Immagina di avere sconti speciali anche dedicati, giorno dopo giorno, mese
                                dopo mese, per tutto il tempo in cui sei un titolare di Ellen Pass.
                            </SectionText>
                        </TextBox>

                        <TextBox>
                            <SectionText>
                                Per garantire la massima sicurezza e autenticità, il processo di verifica tramite il nostro
                                server Discord ufficiale, garantisce che solo gli utenti legittimi possano accedere ai
                                benefici offerti dalle aziende partner. Una volta verificato, potrai godere appieno degli
                                sconti e dei vantaggi riservati esclusivamente ai nostri utenti.
                            </SectionText>
                        </TextBox>

                        <TextBox>
                            <SectionText>
                                <strong>Ma non finisce qui!</strong> Vogliamo che le aziende partner traggano beneficio dalla
                                collaborazione con noi. Pertanto, offriamo loro l'opportunità di promuovere i loro
                                prodotti o servizi attraverso Ellen Pass, senza dover sostenere costi pubblicitari
                                aggiuntivi o pagare commissioni per l'acquisizione di nuovi clienti. Le aziende partner
                                si impegnano semplicemente a riservare sconti speciali agli utenti verificati che
                                possiedono Ellen Pass. Un accordo vantaggioso per tutti!
                            </SectionText>
                        </TextBox>

                        <JoinButton onClick={onClickHandler}>
                            <ButtonText>
                                join discord
                            </ButtonText>

                            <img alt='discord icon' src={DiscordIcon} />
                        </JoinButton>
                    </TextContainer>
                </SectionBox>
            </Section>
        </SectionsBase>
    );
};

export default BenefitsAndAdvantage;
