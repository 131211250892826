import { GlobalContactButton } from 'components/buttons';
import devices from 'style/breakpoints/breakpoints';
import styled from 'styled-components';

export const Section = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    width: 100%;
    max-width: 1200px;
    padding: 0 20px;

    @media ${devices.tablet} {

        /* flex-direction: row; */
    }
`;

export const Title = styled.h2`

    margin: 0;
    padding: 0;

    font-size: 30px;
    text-align: left;

    @media ${devices.tablet} {

        font-size: 40px;
        align-self: center;
    }
`;

export const QuestionContainer = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 40px;
`;

export const QuestionsBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    /* flex-direction: row; */
    gap: 20px;
`;

export const QuestionRow = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
`;

export const QuestionBullet = styled.div`

    width: 20px;
    height: 20px;

    margin: 12px 20px 0 0;

    border-radius: 50%;
    background: white;

    flex-shrink: 0;
`;

export const QuestionText = styled.p`

    margin: 0;
    padding: 0;

    font-size: 20px;
    font-weight: 700;
    text-align: left;

    @media ${devices.tablet} {
        
        font-size: 30px;
    }
`;

export const AnswerText = styled.p`

    margin: 10px 0 0;
    padding: 0;

    font-size: 20px;
    text-align: left;
`;

export const ContactButton = styled(GlobalContactButton)`

    align-self: center;
    margin: 50px 0 0;
`;
