import devices from 'style/breakpoints/breakpoints';
import styled, { css } from 'styled-components';


export const FooterRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 100%;
    min-height: 80px;

    /* background: #1E2251FC; */
`;

export const Content = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-direction: column-reverse;

    width: 100%;
    max-width: 1440px;

    @media ${devices.tablet} {

        flex-direction: row;
    }
`;

export const LogoBox = styled.div`

    img {
        width: 200px;
    }
`;

export const InformationContainer = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    margin: 20px 0 0;

    @media ${devices.tablet} {

        align-items: flex-start;
        justify-content: center;

        flex-direction: row;
        margin: 0;
    }

`;

export const InfoBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 10px;

    margin: 10px 0 0;
`;

export const InfoTitle = styled.h6`

    margin: 0;
    padding: 0;
`;

export const InfoDescription = styled.p<{$isPointer?: boolean}>`

    margin: 0;
    padding: 0;

    font-size: 12px;

    ${({$isPointer}) => $isPointer && (

        css`
            cursor: pointer;
        `
    )}
`;

export const LinkBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 10px;

    @media ${devices.tablet} {

        align-items: flex-end;
        margin: 10px 0 0 20px;
    }

`;

export const LinkText = styled.a`

    font-size: 12px;
    font-style: italic;
    text-decoration: none;
    color: black;

    cursor: pointer;

    ::before ::after {
        text-decoration: none;
        color: unset;
    }
`;

export const SocialContainer = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;
`;

export const SocialBox = styled.a`

    margin: 30px 0 0;
    cursor: pointer;
    
    img {
    
        width: 40px;
        height: 40px;
    
        border-radius: 10px;
    }

    @media ${devices.tablet} {

        margin: 0;
    }
`;
