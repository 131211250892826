import devices from "style/breakpoints/breakpoints";
import styled from "styled-components";


export const Section = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;
    max-width: 1200px;
`;

export const SectionTitle = styled.h2`

    margin: 0;
    padding: 0;

    font-size: 30px;
    text-align: left;

    @media ${devices.tablet} {
        font-size: 40px;
    }
`;

export const ImageBox = styled.div`

    width: 100%;

    img {
        
        width: 100%;
        display: none;
    }

    img:nth-child(2) {
        display: block;
    }

    @media ${devices.tablet} {
        img {
            display: block;
        }
        img:nth-child(2) {
            display: none;
        }
    }
`;
