import devices from 'style/breakpoints/breakpoints';
import styled from 'styled-components';

// assets
import LogoPNG from 'assets/logo-orizzontale.png';

export const MinRangeGuard = styled.div`

    display: block;
    width: 100vw;
    height: 100vh;

    background-image: url(${LogoPNG});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @media ${devices.appMinWidth} {
        display: none;
    }
`;

export const AppContainer = styled.div`

    display: none;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    overflow: hidden;

    @media ${devices.appMinWidth} {
        display: flex;
    }
`;