import styled, { css } from 'styled-components'

export const GlobalContactButton = styled.button<{flavour?: 'ellenikaBlu'}>`

    margin: 0;
    padding: 20px  80px;

    border-radius: 50px;
    border: 5px solid white;
    background: transparent;

    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;

    cursor: pointer;

    ${({flavour}) => (flavour === 'ellenikaBlu') && (

        css`
            color: #1E2251FC;
            border-color: #1E2251FC;
        `
    )}
`