import devices from 'style/breakpoints/breakpoints';
import styled, { css } from 'styled-components';

export const NavbarRow = styled.div`

    display: none;
    align-items: center;
    justify-content: center;

    @media ${devices.tablet} {

        display: flex;
    }

`;
