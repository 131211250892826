import { FC } from 'react';

//assets
import LorenzoGerriIMG from './assets/Lorenzo-Gerri.jpg';
import RiccardoGerriIMG from './assets/Riccardo-Gerri.jpg';

//style
import { SectionsBase } from '../style/Sections.style';
import {
    Section,
    IntroText,
    IntroTitle,
    TeamBox,
    TeamMateBox,
    ImageBox,
    ProfileImage,
    ProfileBox,
    ProfileName,
    Position,
    Description
 } from './style/OurTeam.style';


const OurTeam: FC<{flavour?: 'ellenikaBlue'}> = ({ flavour }) => {
    
    return (
        <SectionsBase flavour={flavour} id='chisiamo'>
            <Section>
                <IntroTitle>
                    Il nostro Team
                </IntroTitle>

                <IntroText>
                    Dall'intuizione e la voglia di innovazione dei fondatori nasce l'idea di fondere presente e
                    futuro attraverso la tecnologia. Ellenika è l'anello di congiunzione tra le aziende, il Web3 e gli
                    utenti. Grazie alla visione e all'impegno che parte dallo staff, fino ad arrivare a tutti i partner,
                    Ellenika è il mezzo per poter fare un upgrade importante verso il futuro.
                </IntroText>

                <TeamBox>
                    <TeamMateBox>
                        <ImageBox>
                            <ProfileImage src={LorenzoGerriIMG} alt='Profile pic'/>
                        </ImageBox>

                        <ProfileBox>
                            <ProfileName>
                                Lorenzo Gerri
                            </ProfileName>

                            <Position>
                                CEM and CO-founder
                            </Position>

                            {/* <Description>
                                Imprenditore titolare di aziende da più di vent'anni, appassionato alla blockchain e con
                                l'idea di riuscire ad unire attività commericiali e aziende al mondo Web3 e metaverso.
                            </Description> */}
                        </ProfileBox>
                    </TeamMateBox>

                    <TeamMateBox>
                        <ImageBox>
                            <ProfileImage src={RiccardoGerriIMG} alt='Profile pic'/>
                        </ImageBox>

                        <ProfileBox>
                            <ProfileName>
                                Riccardo Gerri
                            </ProfileName>

                            <Position>
                                CEO and CO-founder
                            </Position>

                            {/* <Description>
                                Scrittore e grafico pubblicitario, fortemente appassionato di NFT e blockchain. Dopo 
                                svariate esperienze lavorative all'estero, ad oggi il focus è interamente dedicato 
                                all'ambizioso progetto Ellenika Utility, con l'obiettivo di accompagnare aziende 
                                e attività commerciali nel Web3.
                            </Description> */}
                        </ProfileBox>
                    </TeamMateBox>
                </TeamBox>
            </Section>
        </SectionsBase>
    );
};

export default OurTeam;
