import devices from 'style/breakpoints/breakpoints';
import styled from 'styled-components';

export const Section = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    max-width: 1200px;
    padding: 0 20px;
`;

export const IntroText = styled.p`

    margin: 20px 0 0;
    padding: 0;

    font-size: 20px;
    text-align: left;
`;

export const IntroTitle = styled.h2`

    margin: 0;
    padding: 0;

    font-size: 30px;
    text-align: left;

    @media ${devices.tablet} {

        font-size: 40px;
    }
`;

export const TeamBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;

    flex-direction: column;
    gap: 30px;
    width: 100%;
    margin: 50px 0 0;

    @media ${devices.tablet} {

        flex-direction: row;
    }
`;

export const TeamMateBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 30px;

    width: 100%;
    max-width: 500px;

    @media ${devices.tablet} {
       flex-direction: row;
    }
`;

export const ImageBox = styled.div``;

export const ProfileImage = styled.img`

    width: 100%;
    border-radius: 15px;

    @media ${devices.tablet} {
        
        width: 200px;
    }
`;

export const ProfileBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
`;

export const ProfileName = styled.h5`

    width: 100%;
    
    margin: 0;
    padding: 0;
    
    font-size: 24px;
    text-align: left;
`;

export const Position = styled.p`

    width: 100%;

    font-size: 20px;
    text-align: left;
`;

export const Description = styled.p`

    width: 100%;

    font-size: 20px;
    text-align: left;
`;
