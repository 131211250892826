import { FC, useState } from 'react';

// assets
import AppImage from './assets/app.png';

// style
import { SectionsBase } from '../style/Sections.style';
import {
    Section,
    TextContainer,
    SectionTitle,
    SectionTitleMobile,
    SectionText,
    ImageContainer,
    JoinButton
 } from './style/EllenPass.style';


const EllenPass: FC<{flavour?: 'ellenikaBlue'}> = ({flavour}) => {

    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

    const onButtonClick = () => {
        window.location.href = '#contatti';
    };

    const onMouseOverHandler = () => setIsMouseOver(true);
    const onMouseLeaveHandler = () => setIsMouseOver(false);

    return (
        <SectionsBase flavour={flavour}>
            <Section>
                <TextContainer>
                    <SectionTitle>
                        Ellen App
                    </SectionTitle>

                    <SectionText>
                        Ma ecco la grande <strong>novità</strong>: nel secondo trimestre del 2024, lanceremo un'app mobile
                        brevettata che ti consentirà di certificare i tuoi NFT direttamente sul campo tramite la
                        scansione di un semplice Qr code, offrendoti così un'esperienza ancora più completa e
                        immediata. Potrai affiliare aziende, commercianti o locali di qualsiasi tipologia in
                        completa autonomia e riscuotere le tue promozioni direttamente sul posto in due
                        semplici click.
                    </SectionText>

                    <SectionText>
                        Il nostro programma rappresenta un'opportunità eccezionale per le aziende partner
                        per poter promuovere i loro prodotti o servizi, senza costi pubblicitari aggiuntivi o
                        commissioni di acquisizione clienti.
                    </SectionText>

                    <SectionText>
                        Ai possessori di Ellen Pass, offriamo benefici esclusivi con lo scopo di creare una
                        comunità pronta ad accogliere tutte le offerte e godere di privilegi lifetime. Grazie al
                        nostro processo di verifica accurato, garantiamo un'esperienza autentica e vantaggi
                        speciali per i nostri utenti e i partner coinvolti.
                    </SectionText>

                    <SectionText>
                        Unisciti a noi in questa nuova era. Ellenika Utility è qui per offrirti soluzioni
                        innovative e coinvolgenti per il tuo benessere e successo.
                    </SectionText>

                    <JoinButton 
                        onClick={onButtonClick}
                        onMouseOver={onMouseOverHandler}
                        $isMouseOver={isMouseOver}
                        onMouseLeave={onMouseLeaveHandler}
                    >
                        Entra in contatto con noi
                    </JoinButton>
                </TextContainer>

                <ImageContainer>
                    <img alt='app' src={AppImage} />
                </ImageContainer>

                <SectionTitleMobile>
                        Ellen App
                </SectionTitleMobile>
            </Section>
        </SectionsBase>
    );
};

export default EllenPass;
