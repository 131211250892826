import devices from 'style/breakpoints/breakpoints';
import styled from 'styled-components';

export const HamburgerIconBox = styled.div`

    display: block;
    padding: 0 30px 0 0;

    img {
        width: 30px;
        height: auto;
    }

    @media ${devices.tablet} {
        
        display: none;
    }
`;

export const MenuTab = styled.div`

    width: 100%;
    background: white;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
`;

export const Content = styled.div`

    display: flex;
    align-items: flex-end;
    justify-content: center;

    flex-direction: column;
    /* gap: 20px; */

    /* padding: 30px 50px; */

`;

export const LinkBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    width: 100%;

    padding: 20px 40px;

    border-bottom: 1px solid #1E2251FC;

    a {
        width: 100%;

        text-align: right;
        text-transform: uppercase;
        text-decoration: none;

        color: #1E2251FC;
        cursor: pointer;

        ::before ::after{
            color: unset;
        }
    }
`;

export const LinkText = styled.a``;
