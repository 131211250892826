import { FC, RefObject, useRef, useState } from 'react';

//style
import { SectionsBase } from '../style/Sections.style';
import { 
    Section,
    Title,
    ContactText,
    SendMailButton,
    ContactForm,
    NameInput,
    MailInput,
    InputLabel,
    SubmitButton,
    CheckBoxContainer,
    CheckBoxRow,
    CheckBox,
    CheckBoxLabel,
    CheckBoxInfo
} from './style/Contacts.style';


const Contacts: FC<{flavour?: 'ellenikaBlue'}> = ({ flavour }) => {

    const [isReadFlagged, setIsReadFlagged] = useState<boolean>(false);
    const [isAuthorizationFlagged, setIsAuthorizationFlagged] = useState<boolean>(false);
    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
    const nameRef = useRef<HTMLInputElement | null>(null);
    const mailRef = useRef<HTMLInputElement | null>(null);
    
    const readClickHandler = () => setIsReadFlagged(prevState => !prevState);
    const authorizationClickHandler = () => setIsAuthorizationFlagged(prevState => !prevState);
    const onMouseOverHandler = () => setIsMouseOver(true);
    const onMouseLeaveHandler = () => setIsMouseOver(false);

    const sendMailHandler = () => {
        window.open(
            "mailto:info@ellenikautility.com",
            '_blank',
            'noreferrer noopener'
        )
    };

    const submitHandler = (e: any) => {

        e.preventDefault();
        
        if (!isReadFlagged || !isAuthorizationFlagged) return;
        if (nameRef.current === null || mailRef.current === null) return alert('Manca qualcosa...');

        alert(`nome: ${nameRef.current.value}, mail: ${mailRef.current.value}`)
    };

    return (
        <SectionsBase flavour={flavour} id='contatti'>
            <Section>
                <Title>Contatto</Title>

                <ContactText>
                    Entra in contatto con noi ed indicaci la tua azienda preferita.
                    Noi la contatteremo e reserveremo a te un meraviglioso regalo!
                    Compila il form e riceverai il modulo da far compilare alla tua azienda preferita,
                    così poi potrai inviarcelo e noi penseremo al resto.
                </ContactText>

                <SendMailButton 
                    onClick={sendMailHandler}
                    onMouseOver={onMouseOverHandler}
                    $isMouseOver={isMouseOver}
                    onMouseLeave={onMouseLeaveHandler}
                >
                    Inviaci una mail con le tue richieste
                </SendMailButton>

                {/* <ContactForm 
                    onSubmit={submitHandler}
                >
                    <InputLabel>Nome
                        <NameInput ref={nameRef} />
                    </InputLabel>

                    <InputLabel>Mail
                        <MailInput ref={mailRef} />
                    </InputLabel>

                    <CheckBoxContainer>
                        <CheckBoxRow>
                            <CheckBox type='checkbox' onClick={readClickHandler}/>

                            <CheckBoxLabel>
                                *Ho letto, compreso e accetto i termini di {' '}
                                <a href='https://drive.google.com/file/d/1czGvXbIUVNmMZWXCLMPS-F8e8SiptUTb/view?usp=drive_link'>Privacy</a> e {' '}
                                <a href='https://drive.google.com/file/d/1vPn4Z8iQm1mH3rzWGFYs8wBDO6ueTPG7/view?usp=drive_link'>T&C</a>
                            </CheckBoxLabel>
                        </CheckBoxRow>
                    </CheckBoxContainer>

                    <CheckBoxContainer>
                        <CheckBoxRow>
                            <CheckBox type='checkbox' onClick={authorizationClickHandler}/>

                            <CheckBoxLabel>
                                *Autorizzo Ellenika Utility Srl all'utilizzo dei miei dati
                                 in relazione alle condizioni riportate nei documenti {' '}
                                 <a href='https://drive.google.com/file/d/1czGvXbIUVNmMZWXCLMPS-F8e8SiptUTb/view?usp=drive_link'>Privacy</a> {' '}
                                  e <a href='https://drive.google.com/file/d/1vPn4Z8iQm1mH3rzWGFYs8wBDO6ueTPG7/view?usp=drive_link'>T&C</a>
                            </CheckBoxLabel>
                        </CheckBoxRow>

                        <CheckBoxInfo>
                            * opzione obbligatoria
                        </CheckBoxInfo>
                    </CheckBoxContainer>

                    <SubmitButton flavour='ellenikaBlu'>
                        Entra in contatto
                    </SubmitButton>
                </ContactForm> */}
            </Section>
        </SectionsBase>
    );
};

export default Contacts;
