import { FC, useEffect, useState } from 'react';

// assets
import Lucchetto from './assets/lucchetto.png';
import FrecciaSu from './assets/freccia-su.png';

// components
import CookieConsent, { Cookies, resetCookieConsentValue } from 'react-cookie-consent';
import Intro from './Sections/Intro/IntroSection.component';
import CompaniesContact from './Sections/CompaniesContact/CompaniesContact.component';
import BenefitsAndAdvantage from './Sections/BenefitsAndAdvantages/BenefitsAndAdvantage.component';
import ReportTheCompany from './Sections/ReportTheCompany/ReportTheCompany.component';
import OurTeam from './Sections/OurTeam/OurTeam.component';
import Faq from './Sections/FAQ/Faq.component';
import Contacts from './Sections/Contacts/Contacts.component';
import VirtualRoom from './Sections/VirtualRoom/VirtualRoom.component';
import EllenPass from './Sections/EllenPass/EllenPass.component';
import RoadMap from './Sections/RoadMap/RoadMap.component';

// style
import {
    Page,
    AbsoluteImageBox,
    ReturnToTopImage,
    CookieLockImage
 } from './style/Home.style';


const Home: FC<{}> = () => {

    const [isReturnToTopArrowVisible, setIsReturnToTopArrowVisible] = useState<boolean>(false);
    const [isMouseOverArrow, setIsMouseOverArrow] = useState<boolean>(false);
    const [isMouseOverLock, setIsMouseOverLock] = useState<boolean>(false);
    
    const onMouseOverArrowHandler = () => setIsMouseOverArrow(true);
    const onMouseLeaveArrowHandler = () => setIsMouseOverArrow(false);
    const onMouseOverLockHandler = () => setIsMouseOverLock(true);
    const onMouseLeaveLockHandler = () => setIsMouseOverLock(false);

    const returToTopHandler = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    };

    const scrollHandler = () => {

        const userHasScrolled = (window.scrollY > 50);
        setIsMouseOverArrow(false);
        setIsReturnToTopArrowVisible(userHasScrolled);
    };

    const resetCookieHandler = () => {
        
        resetCookieConsentValue();
        window.location.reload();
    };

    const onCloseCookieBannerHandler = () => {
        
        Cookies.set('CookieConsent', 'false', {expires: 100, sameSite: 'Lax'});
        window.location.reload();
    };

    useEffect(() => {
        
        scrollHandler();
        window.addEventListener('scroll', scrollHandler);

        return () => window.removeEventListener('scroll', scrollHandler);
    }, []);

    return (
        <Page>
            <Intro />

            <CompaniesContact flavour='ellenikaBlue'/>

            <BenefitsAndAdvantage />

            <ReportTheCompany flavour='ellenikaBlue'/>

            <VirtualRoom />

            <EllenPass flavour='ellenikaBlue'/>

            <RoadMap />

            <OurTeam />

            <Faq flavour='ellenikaBlue'/>

            <Contacts />

            {isReturnToTopArrowVisible && (
                <AbsoluteImageBox 
                    $isReturnToTopArrow
                    onClick={returToTopHandler}
                    onMouseOver={onMouseOverArrowHandler}
                    onMouseLeave={onMouseLeaveArrowHandler}
                    $isMouseOver={isMouseOverArrow}
                >
                    <ReturnToTopImage alt='Torna su' src={FrecciaSu} />
                </AbsoluteImageBox>
            )}

            <AbsoluteImageBox 
                onClick={resetCookieHandler}
                onMouseOver={onMouseOverLockHandler}
                onMouseLeave={onMouseLeaveLockHandler}
                $isMouseOver={isMouseOverLock}
            >
                <CookieLockImage alt='GDPR' src={Lucchetto} />
            </AbsoluteImageBox>

            <CookieConsent
                expires={100}
                buttonText="ACCETTARE TUTTO"
                declineButtonText="RIFIUTARE TUTTO"
                enableDeclineButton
                flipButtons
                overlay
                style={{
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "width": "100%",
                    "background": "black",
                    "color": "white",
                    "lineHeight": "25px",
                }}
                buttonStyle={{
                    "padding": "20px",
                    "background": "white",
                    "borderRadius": "50px",
                    "border": "5px solid #1E2251FC",
                    "color": "#1E2251FC"
                }}
                declineButtonStyle={{
                    "padding": "20px",
                    "background": "white",
                    "borderRadius": "50px",
                    "border": "5px solid #1E2251FC",
                    "color": "#1E2251FC"
                }}
                overlayStyle={{
                    // "background": "rgba(255, 255, 255, 0.5)"
                    "background": "rgba(0, 0, 0, 0.5)"
                }}

            >
                <button
                    style={{
                        "background": "transparent",
                        "border": "none",
                        "color": "yellow",
                        "fontSize": "25px",
                        "padding": "20px",
                        "cursor": "pointer"
                    }}
                    onClick={onCloseCookieBannerHandler}
                >
                    X
                </button>

                <br />

                Utilizziamo i Cookie per migliorare la tua esperienza di navigazione, 
                offriti pubblicità o contenuti personalizzati e analizzare il nostro traffico. 
                Cliccando sul pulsante “Accettare tutto” l’Utente acconsente all’uso di tutte 
                le tipologie di Cookie utilizzate dal Sito web. Cliccando il pulsante “Rifiuta tutto”, 
                verranno installati solo i Cookie Necessari al funzionamento del Sito. 
                
                <br />
                
                <a href='https://drive.google.com/file/d/1PTXn-NEp9z8ZT_Hnnz07QxCoMAB6T9Ve/view?usp=drive_link'>Clicca qui per il documento sui Cookie utilizzati</a>
            </CookieConsent>
        </Page>
    );
};

export default Home;
