import { FC } from 'react';

//assets
import LogoH from 'assets/logo-orizzontale.png';

//components
import Navbar from './Navbar/Navbar.component';
import HamburgerMenu from './Navbar/HamburgerMenu/HamburgerMenu.component';



//style
import { 
    HeaderRow,
    LogoBox
} from './style/Header.style';


const Header: FC<{}> = () => {

    return (
        <HeaderRow>
            <LogoBox>
                <img src={LogoH} alt='logo' />
            </LogoBox>

            <Navbar />

            <HamburgerMenu />
        </HeaderRow>
    );
};

export default Header;
