import styled from 'styled-components';

export const HeaderRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 1440px;
    height: 100px;
`;

export const LogoBox = styled.div`

    img {

        width: 200px;
        height: auto;
    }
`;

export const Navbar = styled.div``;
