import React from 'react';
import logo from '../logo.svg';
import './style/App.css';

// routing
import { Route, Routes } from 'react-router-dom';

//components
import Home from 'Home/Home.component';
import BenefitsAndAdvantage from 'Home/Sections/BenefitsAndAdvantages/BenefitsAndAdvantage.component';
import CompaniesContact from 'Home/Sections/CompaniesContact/CompaniesContact.component';
import Faq from 'Home/Sections/FAQ/Faq.component';
import OurTeam from 'Home/Sections/OurTeam/OurTeam.component';
import Contacts from 'Home/Sections/Contacts/Contacts.component';
//components
import Header from './Header/Header.component';
import Footer from './Footer/Footer.component';

//style
import { AppContainer, MinRangeGuard } from './style/App.style';


function App() {
    return (
        <>
            <MinRangeGuard />

            <AppContainer>
                <Header />
                    <Routes>
                        <Route 
                            path='/'
                            Component={Home}
                        />
                        <Route 
                            path='/cosafacciamo'
                            Component={CompaniesContact}
                        />
                        <Route 
                            path='/vantaggi'
                            Component={BenefitsAndAdvantage}
                        />
                        <Route 
                            path='/chisiamo'
                            Component={OurTeam}
                        />
                        <Route 
                            path='/faq'
                            Component={Faq}
                        />
                        <Route 
                            path='/contatti'
                            Component={Contacts}
                        />
                    </Routes>
                <Footer />
            </AppContainer>
        </>
    );
}

export default App;
