import { FC } from 'react';

//components
import IntroVideo from './IntroVideo/IntroVideo.component';

// style
import { SectionsBase } from '../style/Sections.style';
import {
    Section,
    // Title,
    // SubTitle,
    VideoBox
 } from './style/IntroSection.style';


const Intro: FC<{flavour?: 'ellenikaBlue'}> = ({ flavour }) => {

    return (
        <SectionsBase flavour={flavour} id='home'>
            <Section>
                {/* <Title>
                    Ellenika Utility
                </Title> */}

                {/* <SubTitle>
                    L'anello di congiunzione con il Web3
                </SubTitle> */}

                <VideoBox>
                    <IntroVideo />
                </VideoBox>
            </Section>
        </SectionsBase>
    );
};

export default Intro;
