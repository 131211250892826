// types
import { Breakpoints, Devices } from './breakpoints.d';


export const breakpoints: Breakpoints = {

    appMinWidth: 319,
    appMaxWidth: 1919,
    mobile: 425,
    tablet: 768,
    desktop: 1200,
    wideDesktop: 1440
};

export const devices: Devices = {

    appMinWidth: `(min-width: ${breakpoints.appMinWidth + 1}px)`,
    appMaxWidth: `(min-width: ${breakpoints.appMaxWidth + 1}px)`,
    mobile: `(min-width: ${breakpoints.mobile + 1}px)`,
    tablet: `(min-width: ${breakpoints.tablet + 1}px)`,
    desktop: `(min-width: ${breakpoints.desktop + 1}px)`,
    wideDesktop: `(min-width: ${breakpoints.wideDesktop + 1}px)`
};

export default devices;
