import styled, { css } from 'styled-components';

export const SectionsBase = styled.div<{flavour?: 'ellenikaBlue'}>`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    padding: 20px;

    background: white;
    color: #1E2251FC;

    ${({flavour}) => flavour === 'ellenikaBlue' && (

        css `
            background: #1E2251FC;
            color: white;
        `
    )}
`;
