import { FC } from 'react';

//routing
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

// component
import LinkBox from './LilnkBox/LinkBox.component';

//style
import { 
    NavbarRow,
} from './style/Navbar.style';
import LinkText from './LinkText/LinkText.component';


const Navbar: FC<{}> = () => {

    return (
        <NavbarRow>
            <LinkBox
                title='Home'
                to='/'
            />

            <LinkBox
                title='Cosa facciamo'
                to='#cosafacciamo'
            />

            <LinkBox
                title='Vantaggi'
                to='#vantaggi'
            />

            <LinkBox
                title='Chi siamo'
                to='#chisiamo'
            />

            <LinkBox
                title='Faq'
                to='#faq'
            />

            <LinkBox
                title='Contatti'
                to='#contatti'
            />

            <LinkText 
                title='Lightpaper'
                href='https://ellenikautility.com/docs/Lightpaper_Ellenika_utility.pdf'
                isDownloadable
            />

            <LinkText 
                title='Web App'
                href='https://app.ellenikautility.com'
            />
        </NavbarRow>
    );
};

export default Navbar;
