import { GlobalContactButton } from "components/buttons";
import devices from "style/breakpoints/breakpoints";
import styled, { css } from "styled-components";


export const Section = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    max-width: 1200px;
    padding: 0 20px;

    img {

        width: 100%;
        max-width: 200px;
    }
`;

export const SectionTitle = styled.h2`

    margin: 0;
    padding: 0;

    font-size: 30px;
    text-align: left;

    @media ${devices.tablet} {
        font-size: 40px;
    }
`;

export const SectionText = styled.p`

    margin: 0;
    padding: 0;

    font-size: 20px;
`;

export const AccessButton = styled(GlobalContactButton)<{$isMouseOver: boolean}>`

    margin: 0;
    border-color: #1E2251FC;
    color: #1E2251FC;

    ${({$isMouseOver}) => $isMouseOver && (
        
        css`

                color: white;
                background: #1E2251FC;
        `
    )}
`;
