import devices from 'style/breakpoints/breakpoints';
import styled from 'styled-components'

export const Section = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
`;

export const Title = styled.h2`

    margin: 0;
    padding: 0;

    font-size: 30px;
    /* text-transform: uppercase; */

    @media ${devices.tablet} {
        font-size: 40px;
    }
`;

export const SectionBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;

    /* margin: 40px 0 0; */

    @media ${devices.tablet} {
        
        flex-direction: row;
    }
`;

export const TextContainer = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    width: 100%;
`;

export const TextBox = styled.div``;

export const TextTitle = styled.h4<{alignement?: 'right'}>`

    font-size: 30px;
    text-align: left;

    @media ${devices.tablet} {
        text-align: ${({alignement}) => alignement ?? 'left'};
    }
`;

export const SectionText = styled.p<{alignement?: 'right'}>`

    margin: 0;
    padding: 0;

    text-align: left;
    font-size: 20px;

    @media ${devices.tablet} {
        text-align: ${({alignement}) => alignement ?? 'left'};
    }
`;

export const ImageBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    align-self: stretch;
    width: 100%;
`;

export const NftImage = styled.img`

    width: 100%;
    max-width: 500px;
`;

export const JoinButton = styled.button`

    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    margin: 30px 0 0;
    padding: 20px  80px;
    
    background: linear-gradient(90deg, #31d7ec 0%, #6671ff 50%, #ea44e5 100%); 
    color: white;
    
    border: 5px solid white;
    border-radius: 50px;
    box-shadow: 2px 2px 5px grey;
    
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;

    cursor: pointer;
    white-space: nowrap;

    img {
        width: 25px;
        margin: 0 0 0 10px;
    }
`;

export const ButtonText = styled.p`

    margin: 0;
    padding: 5px 0 0;
`;
