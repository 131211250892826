import { FC } from 'react';

//style
import {
    VideoBox,
    EllenVideo
} from './style/IntroVideo.style';

const _VIDEO_URL = 'https://ellenikautility.com/assets/video/ellenika-intro.mp4';


const IntroVideo: FC<{}> = () => {

    return (
        <VideoBox>
                {/* <iframe 
                    width="700"
                    height="450"
                    src="https://www.youtube.com/embed/pyJOAQzlsr8"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                /> */}

                <EllenVideo 
                    src={_VIDEO_URL}
                    autoPlay
                    muted
                    loop
                    controls={false}
                />
        </VideoBox>
    );
};

export default IntroVideo;
