import { FC, useState } from 'react';

// routing
import { HashLink as Link } from 'react-router-hash-link';

//assets
import HamgurgerIcon from './assets/hamburge-icon.png';

//style
import { 
    HamburgerIconBox,
    MenuTab,
    Content,
    LinkBox,
    LinkText
} from './style/HamburgerMenu.style';


const HamburgerMenu: FC<{}> = () => {

    const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

    const menuHandler = () => setIsHamburgerMenuOpen(prevState => !prevState);

    return (
        <>
            {!isHamburgerMenuOpen && 
            <HamburgerIconBox onClick={menuHandler}>
                <img alt='Hamburgen icon' src={HamgurgerIcon} />
            </HamburgerIconBox>}

            {isHamburgerMenuOpen && 
            <MenuTab onClick={menuHandler}>
                <Content>
                    <LinkBox>
                        <Link to='/'>
                            Home
                        </Link>
                    </LinkBox>

                    <LinkBox>
                        <Link to='#cosafacciamo'>
                            Cosa facciamo
                        </Link>
                    </LinkBox>

                    <LinkBox>
                        <Link to='#vantaggi'>
                            Vantaggi
                        </Link>
                    </LinkBox>

                    <LinkBox>
                        <Link to='#chisiamo'>
                            Chi siamo
                        </Link>
                    </LinkBox>

                    <LinkBox>
                        <Link to='#faq'>
                            FAQ
                        </Link>
                    </LinkBox>

                    <LinkBox>
                        <Link to='#contatti'>
                            Contatti
                        </Link>
                    </LinkBox>

                    <LinkBox>
                        <LinkText 
                            href='https://ellenikautility.com/docs/Lightpaper_Ellenika_utility.pdf'
                            download={'Lightpaper'}
                        >
                            Lightpaper
                        </LinkText>
                    </LinkBox>

                    <LinkBox>
                        <LinkText href='https://app.ellenikautility.com'>
                            Web App
                        </LinkText>
                    </LinkBox>
                </Content>
            </MenuTab>}
        </>
    );
};

export default HamburgerMenu;
