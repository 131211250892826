import devices from 'style/breakpoints/breakpoints';
import styled from 'styled-components';

export const Section = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
`;

export const Title = styled.h1`

    margin: 0;
    padding: 0 15px;
    
    font-size: 40px;
    text-transform: uppercase;

    @media ${devices.tablet} {
    
        font-size: 60px;
    }
`;

export const SubTitle = styled.h4`

    padding: 0 15px;

    font-size: 20px;
    font-style: italic;

    @media ${devices.tablet} {
        font-size: 25px;
    }
`;

export const VideoBox = styled.div`

    border-radius: 15px;
`;
