import { FC } from 'react';

// assets
import Timeline from './assets/timeline.png';
import RoadmapMobile from './assets/roadmap-mobile.png';

// style
import { SectionsBase } from '../style/Sections.style'; 
import { 
    Section,
    ImageBox,
    SectionTitle
} from './style/RoadMap.style';


const RoadMap: FC<{flavour?: 'ellenikaBlue'}> = ({flavour}) => {

    return (
        <SectionsBase flavour={flavour}>
            <Section>
                <SectionTitle>
                    La nostra RoadMap
                </SectionTitle>
                
                <ImageBox>
                    <img alt='timeline' src={Timeline} />

                    <img alt='roadmap' src={RoadmapMobile} />
                </ImageBox>
            </Section>
        </SectionsBase>
    );
};

export default RoadMap;
