import { GlobalContactButton } from 'components/buttons';
import devices from 'style/breakpoints/breakpoints';
import styled, { css } from 'styled-components';


export const Section = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column-reverse;
    
    max-width: 1200px;
    padding: 0 20px;
    
    @media ${devices.tablet} {

        flex-direction: row;
        gap: 20px;

        align-items: center;
    }
`;

export const TextContainer = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;
`;

export const SectionTitleMobile = styled.h2`

    width: 100%;
    margin: 0;
    padding: 0;

    font-size: 30px;
    text-align: center;

    @media ${devices.tablet} {

        display: none;
    }
`;

export const SectionTitle = styled.h2`

    display: none;
    margin: 0;
    padding: 0;

    font-size: 30px;
    text-align: left;

    @media ${devices.tablet} {
        display: block;
        font-size: 40px;
    }
`;

export const SectionText = styled.p`

    margin: 0;
    padding: 0;

    font-size: 20px;
`;

export const ImageContainer = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    img {

        width: 100%;

        @media ${devices.tablet} {

            min-width: 400px;
        }
    }
`;

export const JoinButton = styled(GlobalContactButton)<{$isMouseOver: boolean}>`

    margin: 30px 0 0;

    align-self: center;

    ${({$isMouseOver}) => $isMouseOver && (
        
        css`

                color: #1E2251FC;
                background: white;
        `
    )}
`;
