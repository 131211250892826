import devices from 'style/breakpoints/breakpoints';
import styled from 'styled-components';

export const VideoBox = styled.div`

    width: 100%;
    min-width: 300px;
    min-height: 170px;
    
    border-radius: 15px;

    iframe {
        width: 100%;
        min-width: 300px;
        height: auto;
        min-height: 170px;
        border: none;
        border-radius: 15px;
    }

    @media ${devices.tablet} {
        
        iframe {
            width: auto;
            min-width: 700px;
            height: auto;
            min-height: 410px;
        }
    }
`;

export const EllenVideo = styled.video`

    width: 100%;
`;
