import { css, styled } from "styled-components";

export const Box = styled.div<{$isMouseOver?: boolean}>`

    padding: 10px 20px;

    a {
        text-transform: uppercase;
        text-decoration: none;

        color: #1E2251FC;
        cursor: pointer;

        ::before ::after{
            color: unset;
        }

    }

    ${({$isMouseOver}) => $isMouseOver && (

        css`
            
            background-color: #1E2251FC;
            border-radius: 15px;
            
            a{ 
                color: white;
            }
        `
    )}     
`;

export const Text = styled.a``;
