import { FC } from 'react';

//style
import { SectionsBase } from '../style/Sections.style';
import {
    Section,
    Title,
    QuestionContainer,
    QuestionsBox,
    QuestionRow,
    QuestionBullet,
    QuestionText,
    AnswerText,
    ContactButton
 } from './style/faq.style';


const Faq: FC<{flavour?: 'ellenikaBlue'}> = ({ flavour }) => {

    const onButtonClick = () => {
        window.location.href = '#contatti';
    };

    return (
        <SectionsBase flavour={flavour} id='faq'>
            <Section>
                <Title>Qualche domanda?</Title>

                <QuestionContainer>
                    <QuestionsBox>
                        <QuestionBullet />

                        <QuestionRow>
                            <QuestionText>
                                Prima di acquistare un immobile in Ellenika zone, devo obbligatoriamente
                                inserire il mio username di State1.io?
                            </QuestionText>

                            <AnswerText>
                                Si, assolutamente. l'username è
                                fondamentale per permetterci di poter accreditare automaticamente la tua proprietà
                                acquistata all'interno del metaverso di State1.
                            </AnswerText>
                        </QuestionRow>
                    </QuestionsBox>

                    <QuestionsBox>
                        <QuestionBullet />

                        <QuestionRow>
                            <QuestionText>
                                Se non sono registrato a State1.io?
                            </QuestionText>

                            <AnswerText>
                                All'interno della sezione "Ellenikazone/state1"
                                nella nostra web app puoi trovare il link che ti reindirizza alla fase di registrazione nel
                                sito ufficiale www.state1.io
                            </AnswerText>
                        </QuestionRow>
                    </QuestionsBox>

                    <QuestionsBox>
                        <QuestionBullet />

                        <QuestionRow>
                            <QuestionText>
                                Che vantaggi ho ad acquistare in Ellenika zone?
                            </QuestionText>

                            <AnswerText>
                                Acquistare una proprietà in
                                Ellenika zone comporta diversi vantaggi. In primis potrai personalizzare dalla A alla Z
                                la tua proprietà diminuendo drasticamente le procedure di edificazione. In secondo
                                luogo le utility che offriranno i nostri partner le potrai riscuotere anche all'interno
                                della nostra area nel metaverso.
                            </AnswerText>
                        </QuestionRow>
                    </QuestionsBox>

                    <QuestionsBox>
                        <QuestionBullet />

                        <QuestionRow>
                            <QuestionText>
                                Come posso richiedere le mie utility?
                            </QuestionText>

                            <AnswerText>
                                Innanzitutto ti invitiamo a registrarti al
                                nostro server discord ufficiale. All'interno del canale troverai la serzione "verify my
                                NFT", a questo punto collega il tuo wallet dove detieni il tuo o i tuoi Ellen pass. Una
                                volta verificato il token ti verrà assegnato un ruolo in base alla rarità dell'nft o degli nft
                                che possiedi. Accedi alla sezione dedicata in base al tuo ruolo e apri un ticket per
                                richiedere codici sconto previsti, oppure lasciaci il tuo contatto cosicchè possiamo
                                filtrarti all'azienda in modo che essa ne sia a conoscenza dello sconto da applicare.
                            </AnswerText>
                        </QuestionRow>
                    </QuestionsBox>

                    {/* <ContactButton onClick={onButtonClick}>
                        Entra in contatto con noi
                    </ContactButton> */}
                </QuestionContainer>
            </Section>
        </SectionsBase>
    );
};

export default Faq;
